(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("react-dom"));
	else if(typeof define === 'function' && define.amd)
		define("cl-react-graph", ["react", "react-dom"], factory);
	else if(typeof exports === 'object')
		exports["cl-react-graph"] = factory(require("react"), require("react-dom"));
	else
		root["cl-react-graph"] = factory(root["React"], root["ReactDOM"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__5329__, __WEBPACK_EXTERNAL_MODULE__3717__) => {
return 